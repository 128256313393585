<template>
  <SearchForm
    class="search-form-container"
    :theme="WELCOME"
  />
</template>
<script>
import { WELCOME } from '../../constants/desktop_autocomplete_theme'

import SearchForm from './SearchForm.vue'

export default {
  name: 'ContainerWelcome',
  components: {
    SearchForm,
  },
  data() {
    return {
      WELCOME,
    }
  },
}
</script>

<style scoped lang="scss">
.search-form-container {
  position: relative;
  width: 88%;
  margin: 15px 6%;
  background: #fafafa;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(#000,.25) inset;
  @media screen and (min-width: 35.5em) {
    width: 450px;
    margin: 15px auto;
    padding: 0 21px;
  }

  :deep(.search-form) {
    width: inherit;
  }
  :deep(.search-form-input) {
    color: #434343;
    border: none;
    font-size: 1em;
    font-weight: bold;
    box-sizing: content-box;
    padding: 9px 0;
    line-height: 180%;
    height: 30px;
    width: inherit;
    background-color: transparent;
    outline: none;
    text-align: center;
    border-radius: 30px;
    &::placeholder {
      color: #444444;
    }
    &::-webkit-search-cancel-button {
      display: none;
    }
  }
  :deep(.search-form-button) {
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    display: block;
    position: absolute;
    bottom: 6px;
    right: 6px;
    cursor: pointer;
    @media screen and (min-width: 35.5em) {
      background-color: #0386f4;
      color: #fff;
      font-size: 15px;
      box-shadow: 0 1px 3px rgba(#000,.25);
      border-radius: 6px;
      transition: background-color 0.5s;
    }

    &:before {
      line-height: inherit;
    }

    &:hover {
      @media screen and (min-width: 35.5em) {
        background-color: #0862BB;
      }
    }
  }
}
</style>
